export function setupResultsSearch() {
	var queries = document.querySelectorAll('.results_query');
	for (let i = 0; i < queries.length; i++) {
		queries[i].addEventListener('click', function(e) {
			e.stopPropagation();
			let queryParam = e.currentTarget.dataset.queryName;
			let valParam = e.currentTarget.dataset.queryValue;

			let url = new URL(location.href);
			let params = new URLSearchParams(url.search);
			params.set(queryParam,valParam); 
			history.replaceState(null, null, "?"+params.toString());
			location.reload();
		});
	}
}