export const know = (function(){

	let my = {};

	my.init = function () {

		const knowNavItems = document.querySelectorAll('.know_nav_item');

		const removeActiveClass = (navItems) => {
			navItems.forEach(navItem => {
				navItem.classList.remove('active');
			});
		};

		const delayScroll = (target) => {
			setTimeout(function() {
				document.getElementById(target).scrollIntoView();
			}, 500);
		};

		knowNavItems.forEach(knowNavItem => {
			knowNavItem.addEventListener('click', (e) => {
				let clickedLink = e.target.closest('.know_btn');
				if(clickedLink) {
					let clickedSection = e.currentTarget;
					let target = clickedLink.getAttribute('href');
					removeActiveClass(knowNavItems);
					clickedSection.classList.add('active');
					delayScroll(target);
				}
			});
		});
	};

	return my;
})();