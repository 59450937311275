export const sponsorModal = (function(){

	let my = {};

	my.init = function () {
		
		const modal = document.getElementById('sponsor_container');

		const modalCloseButton = document.getElementById('sponsor_close_btn');

		const modalOpenButton = document.getElementById('home_sponsor_vendor_btn');

		const formToggleButtons = document.querySelectorAll('.form_toggle');
        
		modalOpenButton.addEventListener('click', () => {
			modal.classList.toggle('open');
			document.querySelector("body").classList.toggle('no_scroll');
		});

		modalCloseButton.addEventListener('click', () => {
			modal.classList.remove('open');
			document.querySelector("body").classList.toggle('no_scroll');
		});

		const removeClass = (arrayItems) => {
			arrayItems.forEach(arrayItem => {
				arrayItem.classList.remove('active');
			});
		};

		formToggleButtons.forEach(formToggleButton => {            
			formToggleButton.addEventListener('click', (e) => {
				let clickedToggle = e.currentTarget;
				let clickedForm = clickedToggle.getAttribute('data-form');
				removeClass(formToggleButtons);
				clickedToggle.classList.add('active');
                
				if(clickedForm === 'vendor') {
					document.getElementById('sponsor_form').classList.remove('active');
					document.getElementById('sponsor_text').classList.remove('active');

					document.getElementById('vendor_form').classList.add('active');
					document.getElementById('vendor_text').classList.add('active');
				}

				if(clickedForm === 'sponsor') {
					document.getElementById('vendor_form').classList.remove('active');
					document.getElementById('vendor_text').classList.remove('active');

					document.getElementById('sponsor_form').classList.add('active');
					document.getElementById('sponsor_text').classList.add('active');
				}
			});
		});
	};

	return my;
})();