export const videoModal = (function(){

	let my = {};

	my.init = function () {
		
		const modal = document.getElementById('home_video_container');

		const modalOpenButtons = document.querySelectorAll('.video_source');

		const closeButton = document.getElementById('close_video');

		modalOpenButtons.forEach(modalOpenButton => {            
			modalOpenButton.addEventListener('click', (e) => {
				let videoSource = e.currentTarget.getAttribute('data-video');
				document.getElementById('video').src = '/static/videos/' + videoSource;
				modal.classList.toggle('open');
				document.querySelector("body").classList.toggle('no_scroll');
				document.getElementById('video').play();
			});
		});

		modal.addEventListener('click', (e) => {
			let container = e.target.closest('div');
			if(container.getAttribute('data-container') === 'container') {
				modal.classList.remove('open');
				document.querySelector("body").classList.remove('no_scroll');
				document.getElementById('video').pause();
			}
		});

		closeButton.addEventListener('click', () => {
			modal.classList.remove('open');
			document.querySelector("body").classList.remove('no_scroll');
			document.getElementById('video').pause();
		});
	};

	return my;
})();