export const helpCta = (function(){

	let my = {};

	my.init = function () {
		const ctaItems = document.querySelectorAll('.cta_card,.results_card');

		ctaItems.forEach(ctaItem => {
			ctaItem.addEventListener('click', e => {
				let clickedItem = e.currentTarget.getAttribute('data-cta-link');
				if (clickedItem) {
					e.stopPropagation();
					window.location.href = clickedItem;
				}
			});
		});
	};

	return my;
})();