export const staffModal = (function(){

	let my = {};

	my.init = function () {
		
		const modal = document.getElementById('staff_container');

		const modalCloseButton = document.getElementById('staff_close_btn');

		const modalOpenButtons = document.querySelectorAll('.staff_email_icon');

		const forms = document.querySelectorAll('.staff_form');

		const removeActiveForm = (forms) => {
			forms.forEach(form => {
				form.classList.remove('active');
			});
		};

		const addActiveForm = (forms, selectedForm) => {
			forms.forEach(form => {
				if(selectedForm === form.getAttribute('data-name')){
					form.classList.add('active');
				}
			});
		};
        
		modalOpenButtons.forEach(modalOpenButton => {
			modalOpenButton.addEventListener('click', (e) => {
				let clickedButton = e.currentTarget;
				let staffForm = clickedButton.getAttribute('data-name');
				document.getElementById('staff_member_form').innerHTML = staffForm.toUpperCase();
				removeActiveForm(forms);
				addActiveForm(forms, staffForm);
				modal.classList.toggle('open');
				document.querySelector("body").classList.toggle('no_scroll');
			});
		});

		modalCloseButton.addEventListener('click', () => {
			modal.classList.remove('open');
			document.querySelector("body").classList.toggle('no_scroll');
		});
	};

	return my;
})();