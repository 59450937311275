
import { becomeSponsorVendor } from './become_sponsor_or_vendor.js';
import { committeeModal } from './committee.js';
import { contactOfficeModal } from './contact_office.js';
import { countdown } from './countdown.js';
import { helpCta } from './cta.js';
import { footerToggle } from './footer.js';
import { navToggle } from './header.js';
import { imageCaro } from './image_carousel.js';
import { know } from './know.js';
import { locationMap } from './map.js';
import { setupResultsSearch } from './results.js';
import { sponsorModal } from './sponsor.js';
import { staffModal } from './staff.js';
import { videoModal } from './video_modal.js';

//Forms
import { becomeSponsorSubmit } from './forms/become_sponsor_form.js';
import { becomeVendorSubmit } from './forms/become_vendor_form.js';
import { committeeSubmit } from './forms/committee_form.js';
import { contactSubmit } from './forms/contact.js';
import { contactOfficeSubmit } from './forms/contact_office_form.js';
import { erikaSubmit } from './forms/erika_form.js';
import { kristiSubmit } from './forms/kristi_form.js';
import { lindaSubmit } from './forms/linda_form.js';
import { litzySubmit } from './forms/litzy_form.js';
import { sponsorSubmit } from './forms/sponsor_form.js';
import { vendorSubmit } from './forms/vendor_form.js';
import { volunteerSubmit } from './forms/volunteer_form.js';

import { scrollAnimation, mapZoom } from './ticket.js';

window.addEventListener('load', function() {
	navToggle.init();
	footerToggle.init();

	if (document.getElementById('scroll_warn')) {
		scrollAnimation.init();
	}

	if (document.getElementById('zoom_buttons')) {
		mapZoom.init();
	}

	if (document.getElementById('contact_office_form')) {
		contactOfficeModal.init();
		contactOfficeSubmit();
	}

	if (document.getElementById('home_video_container')) {
		videoModal.init();
		imageCaro.init();
	}

	if (document.getElementById('sponsor_container')) {
		sponsorModal.init();
		sponsorSubmit();
		vendorSubmit();
	}

	if (document.getElementById('contact_form')) {
		contactSubmit();
	}

	if (document.getElementById('committee_request_form')) {
		committeeModal.init();
		committeeSubmit();
	}

	if(document.getElementById('countdown_wrapper')) {
		countdown.init();
	}

	if(document.getElementById('cta_wrapper') || document.getElementById('results_wrapper')) {
		helpCta.init();
	}

	if(document.getElementById('map')){
		locationMap.init();
	}

	if (document.getElementById('staff_container')) {
		staffModal.init();
		lindaSubmit();
		litzySubmit();
		kristiSubmit();
		erikaSubmit();
	}

	if (document.getElementById('know_nav')) {
		know.init();
	}

	if (document.getElementById('results_search_nav')) {
		setupResultsSearch();
	}

	if (document.getElementById('volunteer_form')) {
		volunteerSubmit();
	}

	if (document.getElementById('become_forms')) {
		becomeSponsorVendor.init();
		becomeSponsorSubmit();
		becomeVendorSubmit();
	}

	if(document.getElementById('about_nav')){
		const menuItems = document.querySelectorAll('#about_nav .btn');
		for (let i = 0; i < menuItems.length; i++) {
			if (menuItems[i].href === document.URL) {
				menuItems[i].classList.toggle('active');
			}
		}
	}
});

