export const contactOfficeModal = (function(){

	let my = {};

	my.init = function () {
		
		const modal = document.getElementById('contact_office_container');

		const modalCloseButton = document.getElementById('contact_office_close_btn');

		const modalOpenButtons = document.querySelectorAll('.ticket_btn');

		modalOpenButtons.forEach(modalOpenButton => {            
			modalOpenButton.addEventListener('click', () => {
				modal.classList.toggle('open');
				document.querySelector("body").classList.toggle('no_scroll');
			});
		});

		modalCloseButton.addEventListener('click', () => {
			modal.classList.remove('open');
			document.querySelector("body").classList.toggle('no_scroll');
		});
	};

	return my;
})();