import {
	getMapsHelper
} from './util/mapsHelper';

/* eslint-disable no-undef */
export const locationMap = (function(){
	let my = {},
		map = document.getElementById('map');

	my.init = function(){
		if(document.getElementById('map')){
			const mapHelper = getMapsHelper();
			mapHelper.ready()
				.then(() => {
					const styleArray =[
						{
							"stylers": [
								{ "saturation": -100 }
							]
						}
					];
					const theMap = mapHelper.createMap({
						element: map,
						locationElementSelector: '#map',
						useRichmarker: true,
						markerReducer: el => {
							return {
								lat: el.getAttribute('lat'),
								lng: el.getAttribute('lng'),
								content: `
                                <a class="map_pin" href="${el.getAttribute('link')}" target="_blank">
                                    <img src="/dist/images/icons/contact/contact_map_icon.svg" alt="Visit facility" />
                                </a>
                                `
							};
						}
					});

					google.maps.event.addListenerOnce(theMap, 'idle', () => {
						theMap.setZoom(15);
						theMap.setOptions({styles: styleArray});
					});
				});
		}
	};

	return my;
})();