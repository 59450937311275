export const countdown = (function(){

	let my = {};

	my.init = function () {

		const dateInput = document.getElementById('countdown_wrapper').getAttribute('date-input');

		const deadlineDate = new Date(dateInput).getTime();

		let timer = () => {
			setInterval(function() {
	
				// Get today's date and time
				let now = new Date().getTime();
	
				// Find the distance between now and the count down date
				let distance = deadlineDate - now;
	
				// Time calculations for days, hours, minutes and seconds
				let days = Math.floor(distance / (1000 * 60 * 60 * 24));
				let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
				let seconds = Math.floor((distance % (1000 * 60)) / 1000);
	
				// Display the result in the element"
				document.getElementById("days").innerHTML = days;
				document.getElementById("hours").innerHTML = hours;
				document.getElementById("minutes").innerHTML = minutes;
				document.getElementById("seconds").innerHTML = seconds;
	
				// If the count down is finished
				if (distance < 0) {
					clearInterval(timer);
					document.getElementById("days").innerHTML = 0;
					document.getElementById("hours").innerHTML = 0;
					document.getElementById("minutes").innerHTML = 0;
					document.getElementById("seconds").innerHTML = 0;
				}
			}, 1000);
		};
		

		timer();
	};

	return my;
})();