export const committeeModal = (function(){

	let my = {};

	my.init = function () {
		
		const modal = document.getElementById('committee_request_container');

		const modalCloseButton = document.getElementById('committee_close_btn');

		const modalOpenButton = document.getElementById('committee_request_form_btn');

		modalOpenButton.addEventListener('click', () => {
			modal.classList.toggle('open');
			document.querySelector("body").classList.toggle('no_scroll');
		});

		modalCloseButton.addEventListener('click', () => {
			modal.classList.remove('open');
			document.querySelector("body").classList.toggle('no_scroll');
		});
	};

	return my;
})();