export const becomeSponsorVendor = (function(){

	let my = {};

	my.init = function () {

		const formToggleButtons = document.querySelectorAll('.form_toggle');

		const removeClass = (arrayItems) => {
			arrayItems.forEach(arrayItem => {
				arrayItem.classList.remove('active');
			});
		};

		formToggleButtons.forEach(formToggleButton => {            
			formToggleButton.addEventListener('click', (e) => {
				let clickedToggle = e.currentTarget;
				let clickedForm = clickedToggle.getAttribute('data-form');
				removeClass(formToggleButtons);
				clickedToggle.classList.add('active');
                
				if(clickedForm === 'become_vendor') {
					document.getElementById('become_sponsor_form').classList.remove('active');

					document.getElementById('become_vendor_form').classList.add('active');
				}

				if(clickedForm === 'become_sponsor') {
					document.getElementById('become_vendor_form').classList.remove('active');

					document.getElementById('become_sponsor_form').classList.add('active');
				}
			});
		});
	};

	return my;
})();