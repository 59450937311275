export const scrollAnimation = (() => {
	const scrollWarn = document.getElementById('scroll_warn');
	const table = document.querySelector('.ticket_information_content');
	let timeout;
	let hasShownWarning = false;

	const showScrollWarn = () => {
		if (hasShownWarning) return;

		scrollWarn.style.display = 'flex';
		scrollWarn.style.opacity = '1';
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			scrollWarn.style.opacity = '0';
			timeout = setTimeout(() => {
				scrollWarn.style.display = 'none';
			}, 1000);
		}, 5000);

		hasShownWarning = true;
	};

	const isElementInViewport = el => {
		const { top, bottom } = el.getBoundingClientRect();
		return top <= window.innerHeight && bottom >= 0;
	};

	const handleVisibility = () => {
		const isMobile = window.innerWidth <= 675;
		if (isMobile && !hasShownWarning) {
			if (isElementInViewport(table)) {
				showScrollWarn();
			} else {
				const checkScroll = () => {
					if (isElementInViewport(table)) {
						showScrollWarn();
						window.removeEventListener('scroll', checkScroll);
					}
				};
				window.addEventListener('scroll', checkScroll);
			}
		}
	};

	return {
		init() {
			window.addEventListener('resize', handleVisibility);
			handleVisibility();
		}
	};
})();

export const mapZoom = (function () {

	let my = {};

	my.init = function () {

		const map = document.getElementById('rodeo_map');

		let mapWidth = 0;
		let maxMapHeight = 0;

		let zoomLevel = 0;

		let zoomInCount = 0;
		let zoomOutCount = 0;

		const zoomInButton = document.getElementById('zoom_in');
		const zoomOutButton = document.getElementById('zoom_out');

		zoomInButton.addEventListener('click', () => {

			mapWidth = map.clientWidth;
			maxMapHeight = document.querySelector('.rodeo_map').clientHeight;
			document.querySelector('.rodeo_map').style.height = `${maxMapHeight}px`;

			zoomInCount++;
			zoomLevel = zoomLevel += 25;

			let newWidth = mapWidth += zoomLevel;

			if (zoomInCount < 4) {
				map.style.width = `${newWidth}px`;
			}

			if (zoomInCount === 3) {
				zoomInCount = 0;
				zoomLevel = 0;
				zoomInButton.classList.remove('active');
				zoomOutButton.classList.add('active');
			}
			console.log(zoomLevel);
		});

		zoomOutButton.addEventListener('click', () => {
			zoomOutCount++;
			zoomLevel = zoomLevel += 25;

			let newWidth = mapWidth -= zoomLevel;

			if (zoomOutCount < 4) {
				map.style.width = `${newWidth}px`;
			}

			if (zoomOutCount === 3) {
				zoomOutCount = 0;
				zoomLevel = 0;
				zoomOutButton.classList.remove('active');
				zoomInButton.classList.add('active');
			}
			console.log(zoomLevel);
		});
	};

	return my;

})();