export const footerToggle = (function(){

	let my = {};

	function _footerToggle() {
        
		document.querySelectorAll('.footer_submenu').forEach(item => {
			item.addEventListener('click', (e) => {
				e.stopPropagation();
				item.classList.toggle('expand');
			});
		});
	}

	my.init = function(){
		_footerToggle();
	};

	return my;
})();