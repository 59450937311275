export const navToggle = (function(){
	let my = {};

	function _mobileToggle() {
		let toggles = document.querySelectorAll('.menu_toggle');
		toggles.forEach(toggle => {
			toggle.addEventListener('click', function(e){
				let clickedButton = e.currentTarget;
				let nav = document.getElementById('mobile_nav');
    
				clickedButton.toggleAttribute('open');
				nav.toggleAttribute('open');
			});
		});
	}

	function _navDrop() {

		const navItems = document.querySelectorAll('.nav_item');

		const removeActive = (array) => {
			array.forEach(arrayItem => {
				arrayItem.classList.remove('active');
			});
		};
        
		document.querySelectorAll('.nav_item').forEach(item => {
			item.addEventListener('click', () => {
				if (item.classList.contains('active') === false && item.querySelector('.drop')) {
					removeActive(navItems);
					item.classList.add('active');
				} else {
					item.classList.remove('active');
				}
			});
		});
	}

	function _subNavDrop() {
        
		document.querySelectorAll('.sub_nav_item').forEach(item => {
			item.addEventListener('click', (e) => {
				e.stopPropagation();
				item.classList.toggle('expand');
			});
		});
	}

	my.init = function(){
		_mobileToggle();
		_navDrop();
		_subNavDrop();
	};

	return my;
})();