import {
	AutomatitCarousel
} from './automatit_carousel.m';

export const imageCaro = (function(){

	let my = {};

	my.init = function () {

		const modal = document.getElementById('home_carousel_container');

		const modalOpenButton = document.getElementById('ticket_carousel_btn');

		const closeModalButton = document.getElementById('close_carousel_modal');

		modalOpenButton.addEventListener('click', () => {
			modal.classList.toggle('open');
			document.querySelector('body').classList.toggle('no_scroll');
		});

		modal.addEventListener('click', (e) => {
			let container = e.target.closest('div');
			if(container.getAttribute('data-container') === 'container') {
				modal.classList.remove('open');
				document.querySelector("body").classList.remove('no_scroll');
			}
		});

		closeModalButton.addEventListener('click', () => {
			modal.classList.remove('open');
			document.querySelector("body").classList.remove('no_scroll');
		});

		const caro = document.querySelector('#ticket_carousel');

		const options = {
			element: caro,
			images: window.slide_images,
			imagesAsBackgrounds: true,
			intervalTiming: false,
			useChevrons: false,
			showDots: false,
		};
			
		window.imageCaro = AutomatitCarousel(options);
	};

	return my;
})();